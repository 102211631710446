import { find } from 'lodash';
import { usePricing } from 'context/PricingProvider';
import { PACKAGE_ORDER } from 'constants/packages';
import { isExceedingMaxDiscount } from 'components/CheckoutPage/utils';
import type { OptionalPackageIdRecord, Package, Pricing } from 'types/pricing';
import type { Market } from 'modules/market';

type FormattedPackagesParams = {
  packages?: Package[];
  market: Market;
};

const useFormattedPackages = ({ market, packages: packagesFromProps }: FormattedPackagesParams) => {
  const { fetching, ...standard } = usePricing('standard');

  let packages = packagesFromProps;

  const pricing: OptionalPackageIdRecord<Pricing> = {
    standard,
    double: usePricing('double'),
    special: usePricing('special'),
    quintuple: usePricing('quintuple'),
  };

  if (!packages) {
    packages = PACKAGE_ORDER[market.id] || PACKAGE_ORDER.other;
  }

  const highDiscountVoucherApplied = Boolean(standard.voucher && isExceedingMaxDiscount(standard));

  if (highDiscountVoucherApplied) {
    packages = [{ id: 'standard' }];
  }

  const preselected = find(packages, 'preselected') || packages?.[0];

  return {
    pricing,
    preselectedPackage: preselected,
    formattedPackages: packages,
    fetching,
  };
};

export { useFormattedPackages };
