import { useCallback, useEffect } from 'react';
import { maxBy } from 'lodash';
import { useRouteData } from 'context/RouteDataProvider';
import { usePricing } from 'context/PricingProvider';
import { useFormattedPackages } from 'hooks/useFormattedPackages';
import { isExistingMarket } from 'utils/market';
import type { OptionalPackageIdRecord, Package, Pricing } from 'types/pricing';
import type { MarketId } from 'modules/market';
import {
  useBusinessPackagesShown,
  usePricingBlocksActions,
  useSelectedPackageId,
} from './usePricingBlocksStore';

const PRECHECK_BUSINESS_MARKETS: MarketId[] = ['italy', 'lithuania', 'romania'];

const usePricingBlocks = () => {
  const selectedPackageId = useSelectedPackageId();
  const businessPackagesShown = useBusinessPackagesShown();
  const { setSelectedPackageId, setBusinessPackagesShown } = usePricingBlocksActions();

  const { market, page } = useRouteData();

  const businessPricing: OptionalPackageIdRecord<Pricing> = {
    bizsm: usePricing('bizsm'),
    bizmd: usePricing('bizmd'),
    bizlg: usePricing('bizlg'),
  };

  const {
    fetching: pricingLoading,
    formattedPackages,
    preselectedPackage,
    pricing: basePricing,
  } = useFormattedPackages({
    market,
  });

  useEffect(() => {
    setSelectedPackageId(businessPackagesShown ? 'bizsm' : preselectedPackage?.id);
  }, [preselectedPackage?.id, businessPackagesShown, setSelectedPackageId]);

  const selectPackage = useCallback(
    (id: Package['id']) => setSelectedPackageId(id),
    [setSelectedPackageId],
  );

  const toggleBusinessPackages = useCallback(
    (shown?: boolean) => setBusinessPackagesShown(shown),
    [setBusinessPackagesShown],
  );

  const businessPackages = [...Object.values(businessPricing)];

  const shownPackages = businessPackagesShown ? businessPackages : formattedPackages;

  const pricing = businessPackagesShown ? businessPricing : basePricing;

  const maxBusinessDiscount = maxBy(businessPackages, 'discount')?.discount;

  const businessPriceToggleShown =
    page?.id === 'precheck' &&
    isExistingMarket({
      market,
      markets: PRECHECK_BUSINESS_MARKETS,
    });

  return {
    businessPackagesShown,
    toggleBusinessPackages,
    shownPackages,
    maxBusinessDiscount,
    pricing,
    selectedPackageId,
    selectPackage,
    pricingLoading,
    businessPriceToggleShown,
  };
};

export { usePricingBlocks };
