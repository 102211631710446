import { create } from 'zustand';
import type { Package } from 'types/pricing';

type PricingBlocksStore = {
  selectedPackageId?: Package['id'];
  businessPackagesShown?: boolean;
  actions: {
    setSelectedPackageId: (id?: Package['id']) => void;
    setBusinessPackagesShown: (shown?: boolean) => void;
  };
};

const usePricingBlocksStore = create<PricingBlocksStore>((set) => ({
  selectedPackageId: undefined,
  businessPackagesShown: false,
  actions: {
    setSelectedPackageId: (id) => set({ selectedPackageId: id }),
    setBusinessPackagesShown: (shown) => set({ businessPackagesShown: shown }),
  },
}));

const useSelectedPackageId = () => usePricingBlocksStore((store) => store.selectedPackageId);
const useBusinessPackagesShown = () =>
  usePricingBlocksStore((store) => store.businessPackagesShown);
const usePricingBlocksActions = () => usePricingBlocksStore((store) => store.actions);

export {
  usePricingBlocksStore,
  useSelectedPackageId,
  useBusinessPackagesShown,
  usePricingBlocksActions,
};
